import StopLimitOrderTable from "@/components/StopLimitOrders/StopLimitOrderTable/index";
import { mapActions } from "vuex";
import expandRow from "@/components/Exchange/Expand/index";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  name: "stop-limit-orders",
  components: {
    StopLimitOrderTable,
    expandRow,
    PersianDatePicker: VuePersianDatetimePicker,
  },
  props: [],
  data() {
    return {
      value: false,
      styles: {
        height: "100vh",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
        backgroundColor: "#141e2c",
      },
      drawerWidth: window.innerWidth >= 769 ? window.innerWidth >= 1000 ? "25%" : "30%" : "100%",
      loading: false,
      pageSize: 10,
      pageNo: 1,
      total: 1,
      symbol: [],
      coinScales: {},
      orderTypes: [
        {
          value: "STOP_LIMIT_CONDITION",
          text: this.$t("stopLimitOrders.stopLimit"),
        },
        {
          value: "OCO_CONDITION",
          text: this.$t("stopLimitOrders.oco"),
        },
      ],
      formItem: {
        symbol: "",
        source: "",
        direction: "",
        date: "",
      },
      orders: [],
    };
  },
  computed: {
    lang: function () {
      return this.getLang;
    },
  },
  watch: {
    lang: function () {
      this.updateLangData();
    },
  },
  created() {
    this.formItem.date = new Date().getTime();
    this.setNavigation("nav-stop-limit-orders");
    this.getAllPreconditionOrders();
    this.getSymbol();
  },
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
      getAllActivePreconditionsByFilter:
        "exchange/GET_ALL_ACTIVE_PRECONDITIONS_BY_FILTER_SERVICE",
      cancelPreconditionOrder: "exchange/CANCEL_PRECONDITION_ORDER_SERVICE",
      getSymbolThumb: "market/GET_SYMBOL_THUMB_SERVICE",
    }),
    clearSelect(e, key) {
      e.stopPropagation()
      this.formItem[key] = ""
    },
    loadDataPage(data) {
      this.pageNo = data;
      this.getAllPreconditionOrders();
    },
    handleSubmit() {
      this.pageNo = 1;
      this.getAllPreconditionOrders();
      this.value = false;
    },
    handleClear() {
      this.formItem = {
        symbol: "",
        source: "",
        direction: "",
        date: new Date().getTime(),
      };
      this.handleSubmit();
    },
    getAllPreconditionOrders() {
      this.loading = true;
      const { symbol, direction, source, date: rangeDate } = this.formItem;
      let startTime = rangeDate[0] || "";
      let endTime = rangeDate[1] || "";
      let payload = {
        symbol: symbol,
        direction: direction,
        startTime: startTime,
        endTime: endTime,
        source: source,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      Object.keys(payload).forEach((key) => {
        if (payload[key] === "" || payload[key] === undefined) {
          delete payload[key];
        }
      });
      this.orders = [];
      this.getAllActivePreconditionsByFilter(payload)
        .then((response) => {
          let preconditionOrdersList = response.data.data.content;
          let rows = [];
          if (preconditionOrdersList.length > 0) {
            this.total = response.data.data.totalElements;
            for (let i = 0; i < preconditionOrdersList.length; i++) {
              let row = preconditionOrdersList[i];
              row.price =
                row.type === "MARKET_PRICE"
                  ? this.$t("stopLimitOrders.marketPrice")
                  : row.price;
              rows.push(row);
            }
            this.orders = rows;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSymbol() {
      this.getSymbolThumb().then((response) => {
        if (response.data.length > 0) {
          this.symbol = response.data;
        }
      });
    },
    updateLangData() {
      this.columns[1].title = this.$t("exchange.time");
      this.columns[2].title = this.$t("uc.finance.trade.symbol");
      this.columns[3].title = this.$t("uc.finance.trade.type");
      this.columns[4].title = this.$t("exchange.direction");
      this.columns[5].title = this.$t("exchange.price");
      this.columns[6].title = this.$t("exchange.num");
      this.columns[7].title = this.$t("exchange.traded");
      this.columns[8].title = this.$t("uc.finance.trade.turnover");
      this.columns[9].title = this.$t("exchange.action");
    },
    cancelCreatedOrder(orderId) {
      let order = this.orders[orderId];
      this.cancelPreconditionOrder(order.id)
        .then(() => {
          this.getAllPreconditionOrders();
          this.$toast.success(this.$t("stopLimitOrders.successCancel"));
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
  },
};
