import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/UI/LoadingComp"

export default {
  name: "stop-limit-order-table",
  components: {
    Loader
  },
  props: {
    dataTable: {
      type: Array,
    },
    current: {
      type: Number,
    },
    total: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      isOrderDetailModalOpen: false,
      cancelOrder: false,
      cancelOrderId: "",
      columns: [
        {
          type: "index",
          align: "center",
          minWidth: 50,
          // render: (h, params) => {
          //   return h(expandRow, {
          //     props: {
          //       skin: params.row.skin,
          //       rows: params.row.detail,
          //     },
          //   });
          // },
        },
        {
          title: this.$t("stopLimitOrders.time"),
          align: "center",
          sortable: true,
          minWidth: 200,
          key: "createdDate",
          render: (h, params) => {
            return h("span", {}, this.dateFormat(params.row.createdDate));
          },
        },
        {
          title: this.$t("stopLimitOrders.pairCoin"),
          align: "center",
          key: "symbol",
          minWidth: 150,
        },
        {
          title: this.$t("stopLimitOrders.orderType"),
          align: "center",
          minWidth: 140,
          render: (h, params) => {
            return h(
              "span",
              params.row.source === "STOP_LIMIT_CONDITION"
                ? this.$t("stopLimitOrders.stopLimit")
                : params.row.source === "OCO_CONDITION"
                ? this.$t("stopLimitOrders.ocoStopLimit")
                : "--"
            );
          },
        },
        // {
        //     title: this.$t("exchange.type"),
        //     minWidth: 140,
        //     render: (h, params) => {
        //         return h(
        //             "span",
        //             params.row.type === "LIMIT_PRICE" ? "سفارش محدود" : "سفارش آنی"
        //         );
        //     }
        // },
        {
          title: this.$t("stopLimitOrders.tradeDirection"),
          align: "center",
          key: "direction",
          minWidth: 120,
          render: (h, params) => {
            const row = params.row;
            const className = row.direction.toLowerCase();
            return h(
              "span",
              {
                attrs: {
                  class: className,
                },
              },
              row.direction == "BUY"
                ? this.$t("stopLimitOrders.buy")
                : this.$t("stopLimitOrders.sell")
            );
          },
        },
        {
          title: this.$t("stopLimitOrders.activationPrice"),
          align: "center",
          sortable: true,
          minWidth: 150,
          key: "activationPrice",
          slot: "activationPrice",
          // render: (h, params) => {
          //   return h(
          //     "span",
          //     params.row.momentMarketPrice >= params.row.activationPrice
          //       ? this.numberWithCommas(
          //           params.row.activationPrice,
          //           this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
          //         ) +
          //           " =" +
          //           ">"
          //       : params.row.momentMarketPrice <= params.row.activationPrice
          //       ? this.numberWithCommas(
          //           params.row.activationPrice,
          //           this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
          //         ) +
          //         " =" +
          //         "<"
          //       : "--"
          //   );
          // },
        },
        {
          title: this.$t("stopLimitOrders.price"),
          align: "center",
          sortable: true,
          minWidth: 150,
          key: "orderPrice",
          render: (h, params) => {
            return h(
              "span",
              this.numberWithCommas(
                params.row.orderPrice,
                this.getPairCoinScales[params.row.symbol].frontBaseCoinScale
              )
            );
          },
        },
        {
          title: this.$t("stopLimitOrders.amount"),
          align: "center",
          sortable: true,
          key: "amount",
          minWidth: 160,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                this.numberWithCommas(
                  params.row.amount,
                  this.getPairCoinScales[params.row.symbol].frontCoinScale
                )
              ),
              h(
                "span",
                {
                  style: {
                    fontSize: "9px",
                    marginLeft: "3px",
                  },
                },
                params.row.type === "MARKET_PRICE"
                  ? params.row.direction === "BUY"
                    ? params.row.baseSymbol
                    : params.row.coinSymbol
                  : params.row.coinSymbol
              ),
            ]);
          },
        },
        {
          title: this.$t("stopLimitOrders.refCode"),
          key: "trackingCode",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h("div", params.row.trackingCode);
          },
        },
        {
          title: this.$t("stopLimitOrders.operation"),
          key: "operate",
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            let actions = [];
            actions.push(
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: {
                    border: "1px solid #f15057",
                    color: "#f15057",
                    // padding: "3px 10px",
                    backgroundColor: "transparent",
                    borderRadius: "0",
                    fontSize: "12px",
                  },
                  on: {
                    click: () => {
                      this.cancelOrder = true;
                      this.cancelOrderId = params.index;
                      // this.cancel(params.index);
                    },
                  },
                },
                this.$t("stopLimitOrders.cancel")
              )
            );
            return h("div", actions);
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPairCoinScales: "market/GET_PAIR_COIN_SCALES",
    }),
  },
  methods: {
    ...mapActions({
      getOrderDetailsService: "exchange/GET_ORDER_DETAILS_SERVICE",
    }),
    dateFormat(tick) {
      return this.$options.filters.dateFormat(tick);
    },
    changePageNumber(pageIndex) {
      this.$emit("changePage", pageIndex);
    },
    getOrderDetails(orderId) {
      this.getOrderDetailsService(orderId).then((response) => {
        this.orderDetailsData = response.data;
        this.isOrderDetailModalOpen = true;
      });
    },
    closeOrderDetailModal() {
      this.isOrderDetailModalOpen = false;
    },
    cancel(orderId) {
      this.$emit("cancelCreatedOrder", orderId);
      this.cancelOrderId = "";
      this.cancelOrder = false;
    },
  },
};
